<template>
  <div class="container">
    <div class="static-page">
      <h1>О компании</h1>
      <p>Недавно в России создана достаточно уникальная комплексная система для привлечения туристов в объекты
        размещения. На сайте alamat.ru совмещены функции туроператора и агрегатора по бронированию, разработанного как
        отечественный аналог Booking.com, но с более широкими и удобными функциональными возможностями. Предусмотрена
        серьезная рекламная кампания по продвижению сайта alamat.ru, что позволит сделать его первой в России
        электронной площадкой по эффективному онлайн-бронированию объекта размещения.</p>

      <br>

      <p>В рамках данного проекта особое внимание уделено разработке механизмов привлечения туристов (отдыхающих) на
        курорты в период межсезонья.</p>

      <br>

      <p>В настоящее время активно создается собственная сеть офисов компании «Аламат» во всех курортах, а также
        обеспечивается взаимодействие со всеми турагентствами. В рамках взаимодействия с объектами размещения
        предусматривается проведение рекламной кампании по отношению к каждому такому объекту. Кроме того, на данном
        сайте alamat.ru для объектов размещения предлагаются услуги по классификации (присвоению «звезд») на льготных
        условиях, а также услуги по подготовке кадров посредством обучения на курсах дополнительного профессионального
        образования в нашем партнерском «Центральном многопрофильном институте» (г. Москва). Кроме того, предоставляются
        услуги по созданию сайта, фото- и видеоконтента, юридическая консультация, бухгалтерское сопровождение и др.</p>

      <br>

      <p>В целях повышения привлекательности объекта размещения для туристов во время бронирования имеется возможность
        одновременно забронировать и средство размещения, и экскурсионный тур на время отдыха, и трансфер.</p>

      <br>

      <p>Вместе с тем в рамках данного проекта создается онлайн-сообщество (онлайн-ассоциация) отельеров и рестораторов.
        Онлайн-сообщество включает в себя в том числе соцсеть отельеров, рестораторов и туристов, где предусмотрены
        широкие возможности взаимодействия и проведения различных мероприятий, используя имеющуюся свою внутреннюю
        систему видеоконференций.
      <p>

        <br>

      <p>Для того, чтобы работать в системе alamat.ru, объектам размещения не нужно дополнительно приобретать
        программные
        продукты «Трэвел Лайн», «Мастер-тур» и другие, так как все необходимые подобные программы с более широким
        функционалом разработаны и внедрены в рамках данного проекта. Это сделало систему «alamat.ru» удобной и
        привлекательной для использования как туристами, так и объектами размещения.</p>

      <br>

      <p>Использование электронной путевки и системы страхования исполнения обязательств делает бронирование через
        данную
        систему более надежной и гарантированной, чем напрямую в самом объекте размещения. Также для туристов
        бронирование через данную систему (alamat.ru) становится более привлекательным из-за наличия динамичных программ
        лояльности.</p>

      <br>

      <p>Таким образом, на данной площадке эффективно реализуются функции туроператора и агрегатора по аналогии с
        Booking.com, что, безусловно, является современным инновационным подходом и существенно повышает актуальность и
        привлекательность данной площадки.</p>

      <br>

      <p>Перечень возможностей на платформе Аlamat.ru:</p>

      <br>

      <ul>
        <li>онлайн бронирование;</li>
        <li>наличие личного кабинета для объекта размещения и туристов;</li>
        <li>использование электронной путевки с электронной цифровой подписью;</li>
        <li>реклама Вашего объекта размещения;</li>
        <li>Использование Google камеры (номера и территория) на льготных основаниях;</li>
        <li>ваш объект размещения будет включен в турпакет, что увеличит поток туристов в межсезонье;</li>
        <li>персональный менеджер по сопровождению;</li>
        <li>реализована возможность оставить отзыв в видео формате;</li>
        <li>фото - и видео-съемки;</li>
        <li>повышение квалификации и профессиональная переподготовка на льготных условиях;</li>
        <li>проведение процедуры классификации на льготных условиях;</li>
        <li>юридическая консультация;</Li>
        <li>бухгалтерское сопровождение и аудит;</li>
      </ul>

      <br>

      <p>У нас имеются аргументы, доказывающие, что сотрудничать туристам с нами будет выгоднее, чем напрямую с
        объектами размещения:</p>

      <br>

      <ul>
        <li>электронная путевка;</li>
        <li>страховка невыполнения обязательств;</li>
        <li>⦁ стоимость экскурсий, приобретаемых в пакетном туре ниже, чем при приобретении в объекте размещения
          (санатории, гостинице);
        </li>
      </ul>

      <br>

      <p style="margin-bottom: 50px;">Используя современные технологии в виде 3D, мы в состоянии представить Ваш
        санаторий (гостиницу) на нашей платформе в достойном современном виде, что скажется на результатах данной
        рекламной кампании Вашего объекта в период межсезонья.</p>

    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>
